import React from "react";
import { PostForm, PostTable, PostView } from "../components";

/**
 * This page shows all posts in Tawreed platform
 * @returns
 */
export const PostsPage: React.FC = () => <PostTable />;


/**
 * This page shows details about one post or create new
 * @returns
 */
export const PostFormPage: React.FC = () => <PostForm />;



/**
 * This page shows details about one post or create new
 * @returns
 */
export const PostViewPage: React.FC = () => <PostView />;


