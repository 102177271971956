import React, { useState } from "react";
import { GlobalContext } from "../../../../context";
import { TawreedTable, TawreedTableProps } from "../../../../common";
import { CssUtils } from "../../../../helpers/css";
import { tawreedTranslationService } from "../../../../common/translations";
import { PrimeIcons } from "primereact/api";
import { classNames } from "primereact/utils";
import { ColumnBodyOptions } from "primereact/column";
import { TawreedTableEditorCallback } from "../../../../common/components/table/editors";
import { Button } from "primereact/button";
import { PostImageUploadDialog } from "./post-upload-dialog";

export const PostImageTable: React.FC<any> = (props) => {
    const [state, setState] = useState<boolean>(false);
    const { constants: { constants } } = React.useContext(GlobalContext);
    const [visible, setVisable] = useState<{ visable: boolean, data: Array<string>, title: string; }>({ visable: false, data: [], title: "" });


    const downloadButton = (rowData: any, options: ColumnBodyOptions, callback: TawreedTableEditorCallback) => {


        return <React.Fragment>
            {/* <Button
            style={{ width: "10%", height: "35px" }}
            icon={PrimeIcons.DOWNLOAD}
            className="p-button-praimary p-button-text"
            loading={props.loading}
            onClick={(e) => {
                //setVisable({ visable: true, data: rowData.files, title: rowData.title, });
                props.downloadDocumentFile(rowData.fileId, rowData.title)
                e.preventDefault();
            }}
        >
        </Button> */}
        <Button
            style={{ width: "10%", height: "35px" }}
            icon={PrimeIcons.TRASH}
            className="p-button-danger p-button-text ml-1"
            loading={props.loading}
            onClick={(e) => {
                props.delelteFile(rowData.fileId)
                e.preventDefault();
            }}
        >
        </Button></React.Fragment>
    }
    const hide = () => {
        setState(false);
    }

    const accept = (data: { file: File, title: string }) => {
        
        props.addDocumentFile({
            file: {fileName: data.file.name,
                value: data.file,
            },
            title: data.title
        });
    }
    const tawreed: TawreedTableProps = {
        ds: {
            mode: 'basic',
            data: props.data ? props.data : [],
        },
        name: 'lbl_posts_images',
        outLoader: props.loading,
        dataKey: '',
        columns: [

            {
                field: "title",
                header: "lbl_posts_images_title",
                style: CssUtils.width('70%'),
                body: (options: any) => {
                    return (
                        <p>{options.title}</p>
                    )
                },
            },
            {
                dataType: 'numeric',
                field: "download",
                style: CssUtils.width('30%'),
                body: downloadButton
            },
        ],
        header: {
            left: {
                search: false,
                custom: (
                    <span>
                        {tawreedTranslationService.translate(props.title)}
                    </span>
                )
            },
            right: props.data.length === 0 ? {
                clear: false,
                custom: (
                    <div className="formgroup-inline">
                        <i className={classNames(PrimeIcons.PLUS, 'bouns_table_plus')} onClick={() => setState(true)}></i>
                    </div>
                )
            }: undefined
        }
    };

    return (
        <React.Fragment>
            <div className="col-12 md:col-12"><TawreedTable {...tawreed} /></div>
            <PostImageUploadDialog
                visible={state}
                hide={hide}
                accept={accept}
            />
        </React.Fragment>
    );
}
