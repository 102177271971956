import { ReportDto, ReportServiceKnownName, StatementReportItem } from "../dtos";
import { DocumentReportParamsMapper, DocumentsReportRepository, InvoiceReportParamsMapper, InvoicesReportRepository, OrderReportParamsMapper, OrdersReportRepository, StatementReportParamsMapper, StatementsReportRepository, TrialBalanceReportParamsMapper, TrialBalanceReportRepository } from "../../data";
import { TawreedConstants } from "../../../constants";
import { JObject } from "../../../../common/data/models";

export class ReportService {

    // repositories
    private readonly documents = new DocumentsReportRepository();
    private readonly invoices = new InvoicesReportRepository();
    private readonly orders = new OrdersReportRepository();
    private readonly statements = new StatementsReportRepository();
    private readonly trialBalance = new TrialBalanceReportRepository();

    /**
     *
     * @param type
     * @param params
     */
    public generate(type: ReportServiceKnownName, params: JObject, scale:number,
        constants: TawreedConstants | undefined ): Promise<ReportDto<any>> {
        if (type === 'documents') {
            return this.documents.generate(DocumentReportParamsMapper.fromJson(params), scale, constants);
        } else if (type === 'statements-per-partner') {
            return this.statements.generate(StatementReportParamsMapper.fromJson(params), scale, constants);
        } else if (type === 'statements-per-account') {
            return this.statements.generate(StatementReportParamsMapper.fromJson(params), scale, constants);
        } else if (type === 'orders') {
            return this.orders.generate(OrderReportParamsMapper.fromJson(params), scale, constants, false);
        } else if (type === 'returned-orders') {
            return this.orders.generate(OrderReportParamsMapper.fromJson(params), scale, constants, true);
        } else if (type === 'invoices') {
            return this.invoices.generate(InvoiceReportParamsMapper.fromJson(params), scale, constants, false);
        } else if (type === 'returned-invoices') {
            return this.invoices.generate(InvoiceReportParamsMapper.fromJson(params), scale, constants, true);
        } else if (type === 'trial-balance') {
            return this.trialBalance.generate(TrialBalanceReportParamsMapper.fromJson(params), scale, constants);
        }
        return Promise.reject('unknown-report-type');
    }


    public export(url: string): void {
        // HERE WE CAN USE ANY REPOSITORY
        this.documents.export(url);
    }

    public getDetails(id: number): Promise<StatementReportItem> {
        return this.statements.getDetails(id);
    }
}
