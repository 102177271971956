import { JObject } from "../../../../../common/data/models";
import { buildFilterMetaToObject, buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result } from "../../../../../common/pagination";
import { endpoints } from "../../../../../constants/endpoints";
import { tawreedHttpService } from "../../../../../http";
import { StoreProductsService } from "../../../../catalog/store-products/domain";
import { PartnerDto, PartnersService } from "../../../../partners/domain";
import { CustomerCartDto, CustomerStoreCartDto, CustomerStoreCartItemDto, paymentModeDto, SaleOfferQuery, salesOfferDetailsDto } from "../../domain";
import { CustomerCartMapper, CustomerStoreCartItemMapper, PaymentModeMapper,SalesOfferDetailsMapper } from "../mappers";
import { salesOfferMapper } from "../../domain/mappers/sales-offer";
import { CustomerCart, CustomerStoreCartItem } from "../models";

export class CartRepository {

    private readonly partners: PartnersService;
    private readonly products: StoreProductsService;

    constructor() {
        this.partners = new PartnersService();
        this.products = new StoreProductsService();
    }


    /**
     * Search carts
     * @param arg
     * @returns
     */
    public oldSearch(arg: PaginatedRequestParams): Promise<PaginatedResponse<CustomerCartDto>> {
        const params = {
            sort: arg.sort ? buildSortParamsToString(arg.sort) : undefined,
            page: arg.page,
            size: arg.size
        };
        const data = arg.keyword ?? '';

        return tawreedHttpService
            .post<string, Result<CustomerCart>>(endpoints.CARTS_SEARCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: {
                        ...res!.data!, content: res!.data!.content.map(e => {
                            return CustomerCartMapper.toDto(e)
                        }) ?? [],
                    }
                }
            });
    }

    public searchItems(cartId: number, customerId: number, arg: PaginatedRequestParams): Promise<PaginatedResponse<CustomerStoreCartItemDto>> {
        const params = {
            sort: arg.sort ? buildSortParamsToString(arg.sort) : undefined,
            page: arg.page,
            size: arg.size
        };
        const data  = {cartId: cartId, customerId: customerId};
        return tawreedHttpService
            .post<JObject, Result<CustomerStoreCartItem>>(endpoints.CART_GET_GET_ITEMS, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: {
                        ...res!.data!, content: res!.data!.content.map(e => {
                            return CustomerStoreCartItemMapper.toDto(e)
                        }) ?? [],
                    }
                }
            });
    }
    public search(arg: PaginatedRequestParams): Promise<PaginatedResponse<CustomerCartDto>> {
        const params = {
            sort: arg.sort ? buildSortParamsToString(arg.sort) : undefined,
            page: arg.page,
            size: arg.size
        };
        const data = {
            ...buildFilterMetaToObject(arg.keyword, arg.filters),
        }

        return tawreedHttpService
            .post<JObject, Result<CustomerCart>>(endpoints.CARTS_SEARCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: {
                        ...res!.data!, content: res!.data!.content.map(e => {
                            return CustomerCartMapper.toDto(e)
                        }) ?? [],
                    }
                }
            });
    }
    /**
     * Create or save cart instance
     * @param data
     * @returns {Promise<CustomerCart>}
     */
    public async addItem(data: JObject): Promise<number> {
        return tawreedHttpService
            .post<JObject, { customerId: number }>(endpoints.CART_ITEMS_ADD, data)
            .then(() => data['customerId']);
    }
    public async addOfferItem(data: JObject): Promise<number> {
        return tawreedHttpService
            .post<JObject, { customerId: number }>(endpoints.CART_OFFER_ITEMS_ADD, data)
            .then(() => data['customerId']);
    }

    public async updateItem(data: JObject): Promise<boolean> {
        return tawreedHttpService
            .post<JObject, { cartId: number }>(endpoints.CART_ITEMS_UPDATE, data)
            .then((res) => !!(res && res.data && res.data.cartId));
    }

    public async deleteItem(id: number): Promise<boolean> {
        return tawreedHttpService
            .post<number, { cartId: number }>(endpoints.CART_ITEMS_DELETE, id)
            .then((res) => !!(res && res.data && res.data.cartId));
    }

    public async deleteCart(id: number): Promise<boolean> {
        return tawreedHttpService
            .post<number, { cartId: number }>(endpoints.CART_CART_DELETE, id)
            .then((res) => !!(res && res.data && res.data.cartId));
    }

    public async checkout(data: JObject): Promise<boolean> {
        const form = {
            "cartId": data['cartId'],
            // "paymentMethodId": data['paymentMethodId'],
            // "paymentTerm": data['paymentTerm']
        };
        return tawreedHttpService
            .post<JObject, { cartId: number }>(endpoints.CART_CART_CHECKOUT_ALL, form)
            .then((res) => !!(res && res.data && res.data.cartId));
    }

    public async checkoutAll(data: JObject): Promise<boolean> {
        return tawreedHttpService
            .post<JObject, { cartId: number }>(endpoints.CART_CART_CHECKOUT_ALL, data)
            .then((res) => !!(res && res.data && res.data.cartId));
    }

    public async getDetails(id: number, getCustomer: boolean, priceListId?: any, salesOfferId?: any): Promise<CustomerCart> {
        const data = { customerId: id, priceListId: priceListId }
        return tawreedHttpService
            .post<JObject, JObject>(endpoints.CART_GET, data, undefined, undefined, 'error')
            .then(cart => {
                let cartItem = cart.data?.filter((el: any) => {
                    return el.priceListId === +data.priceListId
                })
                
                if (cartItem && cartItem.length > 0) {
                    let carts = cartItem[0].carts?.filter((el: any) => {
                        if(salesOfferId)
                            return el.salesOfferId === +salesOfferId;
                        else
                            return el.salesOfferId === null
                    })
                    cartItem[0]["carts"] = carts;
                    cart['data'] = cartItem[0];
                }

                if (cart && cart.data) {
                    console.log(cart.data)
                    if (getCustomer) {
                        return this.getCustomer(id).then(customer => {
                            if (customer) {
                                cart.data!['customer'] = customer;
                            }
                            return CustomerCartMapper.fromJson(cart.data!);
                        });
                    }
                    return CustomerCartMapper.fromJson(cart.data!)
                }

                throw new Error();
            });
    }

    public async getDetails1(id: number, customerId: number, getCustomer: boolean): Promise<CustomerCart> {
        const data = { cartId: id, customerId: customerId }
        return tawreedHttpService
            .post<JObject, JObject>(endpoints.CART_GET_BY_ID, data, undefined, undefined, 'error')
            .then(cart => {
                if (cart && cart.data) {
                    console.log(cart.data)
                    if (getCustomer) {
                        return this.getCustomer(cart.data.customerId).then(customer => {
                            if (customer) {
                                cart.data!['customer'] = customer;
                            }
                            return CustomerCartMapper.fromJson1(cart.data!);
                        });
                    }
                    return CustomerCartMapper.fromJson1(cart.data!)
                }

                throw new Error();
            });
    }

    public async getCustomer(customerId: number): Promise<PartnerDto> {
        return this.partners.getDetails(customerId).then(res => res);
    }
    public async getItemDetails(data: JObject): Promise<CustomerStoreCartItemDto> {
        return tawreedHttpService.post<JObject, JObject>(endpoints.CART_ITEM_CALC, data, undefined, undefined, 'error').then((res) => {
            return CustomerStoreCartItemMapper.toDto(CustomerStoreCartItemMapper.fromJson(res.data!));
        })
    }
    public async getSalesOfferDetails(data: number): Promise<Array<salesOfferDetailsDto>> {
        return tawreedHttpService.post<number, JObject>(endpoints.PACKAGES_DETAILS, data, undefined, undefined, 'error').then((res) => {
            const result: Array<salesOfferDetailsDto> = [];
            res.data?.forEach((el: any) => {
                result.push(SalesOfferDetailsMapper.fromJson(el));
            })

            return result;
        })
    }
    public async getPaymentModes(priceListId: number): Promise<Array<paymentModeDto>> {
        return tawreedHttpService.post<number, JObject>(endpoints.CART_PAYMENT_MODE, priceListId, undefined, undefined, 'error').then((res) => {
            const result: Array<paymentModeDto> = [];
            res.data?.forEach((el: any) => {
                result.push(PaymentModeMapper.toDto(PaymentModeMapper.fromJson(el)));
            })

            return result;
        })
    }

    public searchSalesoffer(q: SaleOfferQuery, pagination: PaginatedRequestParams): 
            Promise<PaginatedResponse<JObject>> {
        const params = {
            sort: pagination.sort ? buildSortParamsToString(pagination.sort) : undefined,
            page: pagination.page,
            size: pagination.size,
        };
        const data = { keyword: q.keyword, criteria: [{fieldName: "ZoneId",operator:"in",value:q.zoneId}]};
        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.PACKAGES_SAERCH, data, params, undefined, 'error');
    }
}
