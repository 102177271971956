import React from "react";
import { TawreedTable, TawreedTableProps } from "../../../../../common";
import { ReportTableDto, ReportTableTotalDto, Urls } from "../../../domain";
import { TawreedReportTableTotals } from "./totals";

export type TawreedReportTableProps = ReportTableDto & { onUrls: (e: Urls | undefined) => void };

export const TawreedReportTable: React.FC<TawreedReportTableProps> = ({ onUrls, dataKey, columns, filter, onSearch, actions,onclick }) => {

    const [totals, setTotals] = React.useState<ReportTableTotalDto>();
    const search = React.useCallback((req) => {
        return onSearch(req)
            .then(res => {
                if (res.data && res.data.data && res.data.data.first) {
                    onUrls(res.urls);
                    setTotals(res.totals);
                }
                console.log(res.data);                
                return res.data;
            });

    }, [onSearch, onUrls]);

    const tawreed: TawreedTableProps = {
        header: false,
        pagination: true,
        dataKey: dataKey,
        name: 'tawreed.reports.table',
        title: undefined,
        ds: {
            mode: 'lazy',
            onSearch: (req) => search(req),
        },
        columns: columns.map(e => { return { ...e, filter: false } }),
        row: actions ? { 
            column: actions,
             onClick: onclick
             } : undefined,
        filter: {
            filterDisplay: 'menu',
            initial: filter,
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'txnDate',
                sortOrder: -1,
                multiSortMeta: undefined,
            },
        },
    }

    return (
        <React.Fragment>
            {
                totals &&
                <TawreedReportTableTotals value={totals} />
            }
            <TawreedTable {...tawreed} />
        </React.Fragment>
    )
}
