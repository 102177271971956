import { JObject } from "../../../../../common/data/models"
import { DateUtils } from "../../../../../helpers/date"
import { HistoryTrackingItem } from "../models"

export const HistoryItemMapper = {

    fromJson: (model?: JObject): HistoryTrackingItem => {
        return {
            createdAt: DateUtils.fromISO(model?.createdAt),
            eventType: model?.titleAr,
            historyId: model?.titleEn,
            orderId: model?.orderId,
            partnerName: model?.partnerName,
            userName: model?.userName,
            message: model?.message
        }
    },
}
