import { JObject } from "../../../../../common/data/models";
import { TagDto } from "../../domain";
import { Tag } from "../models";

export const TagMapper = {
    /**
     * Create model from json-object
     * @param json
     * @returns {Tag model}
     */
    fromJson: (json?: JObject): Tag =>{
        return {
            storeProductId: json?.storeProductId,
            storeProductTagId: json?.storeProductTagId,
            tagId: json?.tagId,
            tagName: json?.tagName
        }
    },

     /**
     * Create model from json-object
     * @param json
     * @returns {Tag model}
     */
     toDto: (model?: Tag): TagDto =>{
        return {
            storeProductId: model?.storeProductId,
            storeProductTagId: model?.storeProductTagId,
            tagId: model?.tagId,
            tagName: model?.tagName
        }
    }
}
