import { PartnersRepository, PartnerType } from "../../data";
import { ConnectionDto, MappingDto, PartnerDto } from "../dtos";
import { buildPaginatedRequest, PaginatedRequestParams, PaginatedResponse } from "../../../../common/pagination";

export class PartnersService {

    private readonly repo: PartnersRepository;

    constructor() {
        this.repo = new PartnersRepository();
    }

    public search(type: PartnerType | undefined, query: string, active: boolean | undefined): Promise<PaginatedResponse<PartnerDto>>;
    public search(type: PartnerType | undefined, request: PaginatedRequestParams): Promise<PaginatedResponse<PartnerDto>>;

    public search(...args: [PartnerType | undefined, string, boolean | undefined] | [PartnerType | undefined, PaginatedRequestParams]): Promise<PaginatedResponse<PartnerDto>> {
        let request: PaginatedRequestParams;
        if (args.length === 3) {
            const [partnerType, query, active] = args;

            request = buildPaginatedRequest({
                sort: { sortOrder: 1, sortField: 'partnerName', multiSortMeta: undefined },
                filters: {
                    partnerType: {
                        operator: 'AND',
                        constraints: [
                            {
                                value: partnerType,
                                matchMode: "equals",
                            },
                        ]
                    },
                    partnerName: {
                        operator: 'AND',
                        constraints: [
                            {
                                value: query,
                                matchMode: "contains",
                            },
                        ],
                    },
                    statusId: {
                        operator: 'AND',
                        constraints: [
                            {
                                value: 3,
                                matchMode: "equals",
                            },
                        ],
                    },
                    active: {
                        operator: 'AND',
                        constraints: [
                            {
                                value: active,
                                matchMode: "equals",
                            },
                        ],
                    },
                },
                size: 250,
            });

        } else if (args.length === 2) {
            const [partnerType, req] = args;

            request = buildPaginatedRequest({
                ...req,
                filters: {
                    ...req.filters,
                    partnerType: {
                        operator: 'AND',
                        constraints: [
                            {
                                value: partnerType,
                                matchMode: "equals",
                            },
                        ]
                    },
                },
            });
        } else {
            request = buildPaginatedRequest({});
        }
        return this.repo.search(request);
    }

    public searchConnections(request: PaginatedRequestParams): Promise<PaginatedResponse<ConnectionDto>> {
       
        return this.repo.searchConnections(request);
    }
    public setClassification(ids: number[], classification:number|undefined): Promise<boolean> {
        return this.repo.setClassification(ids,classification);
    }
    public addClassification(fromPartnerId: number, toPartnerId: number ,classification:number|undefined): Promise<boolean> {
        return this.repo.addClassification(fromPartnerId, toPartnerId, classification);
    }
    public setClassificationItem(id: number, classification:number|undefined): Promise<boolean> {
        return this.repo.setClassificationItem(id,classification);
    }

    /**
     * Create or update partner instance
     * @param type
     * @param form
     */
    public createOrUpdate(type: PartnerType, form: PartnerDto): Promise<PartnerDto> {
        return this.repo.createOrUpdate(type, form);
    }

    /**
     * Delete partner by id
     * @param id
     */
    public delete(id: number): Promise<boolean> {
        return this.repo.delete(id);
    }


    /**
     * Delete all partners by ids
     * @param ids
     */
    public deleteAll(ids: number[]): Promise<boolean> {
        return this.repo.deleteAll(ids)
    }

    /**
     * Verify partner by id
     * @param id
     */
    public verify(id: number): Promise<PartnerDto> {
        return this.repo.verify(id);
    }
    /**
   * Verify partner by id
   * @param id
   */
    public createWallet(id: number): Promise<PartnerDto> {
        return this.repo.createWallet(id);
    }

    /**
     * Get partner details by id
     * @param id
     */
    public getDetails(id: number): Promise<PartnerDto> {
        return this.repo.getDetails(id);
    }
    
    public getDetailsByConnectionId(id: number): Promise<PartnerDto> {
        return this.repo.getDetailsByConnectionId(id);
    }
    public getStoreMapping(id: number): Promise<MappingDto[]>{
        return this.repo.getStoreMapping(id);
    }

    public createOrUpdateStoreMapping(data: MappingDto[]): Promise<MappingDto[]> {
        return this.repo.createOrUpdateStoreMapping(data);
    }

    public deleteMapping(id:number): Promise<boolean> {
        return this.repo.deleteMapping(id);
    }
}
