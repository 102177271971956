import {OrderDto, orderService} from "../../../../../domain";
import {OrderFormListener} from "../../order-form.props";

export const onCancel = ( orderId: number, reasonCode: number, reasonAr:string | undefined,
    reasonEn: string | undefined, listener: OrderFormListener | undefined): Promise<OrderDto | undefined> => {

    return orderService
    .cancel(orderId, reasonCode, reasonAr, reasonEn)
        .then(res => {
            if (res && listener) {
                listener({type: 'cancelled', sender: res});
            }
            return res;
        })
        .catch(() => {
            return undefined;
        });
}
export const onReturnCancel = (orderId: number, listener: OrderFormListener | undefined): Promise<OrderDto | undefined> => {
    return orderService
        .cancelReturn(orderId)
        .then(res => {
            if (res && listener) {
                listener({type: 'cancelled', sender: res});
            }
            return res;
        })
        .catch(() => {
            return undefined;
        });
}
