import React from 'react'
import {useParams} from 'react-router-dom';
import {OrderForm} from '../components';
import {OrdersTable} from '../components/orders-table';
import { OrderFormFullHistory } from '../components/order-form/order-form-full-history';

/**
 *
 */
export const OrdersPage: React.FC = () => <OrdersTable name="tbl.sales.orders"/>;

/**
 *
 * @returns
 */
export const OrderPage: React.FC = () => {

    const {id} = useParams<{ id: string | undefined }>();
    return <OrderForm orderId={id ? Number.parseInt(id) : undefined} showBack/>
}
export const OrderTrackingHistoryPage: React.FC = () => {

    const {id} = useParams<{ id: string | undefined }>();
    return <OrderFormFullHistory/>
}

