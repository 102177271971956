import React, { useState } from "react";
import { GlobalContext } from "../../../../context";
import { FileService } from "../../../upload";
import { ColumnBodyOptions } from "primereact/column";
import { TawreedTableEditorCallback } from "../../../../common/components/table/editors";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { TawreedTable, TawreedTableProps } from "../../../../common";
import { CssUtils } from "../../../../helpers/css";
import { DateUtils } from "../../../../helpers/date";
import { tawreedTranslationService } from "../../../../common/translations";




export const TransactionDocumentTable: React.FC<any> = (props) => {

    const downloadButton = (rowData: any, options: ColumnBodyOptions, callback: TawreedTableEditorCallback) => {

        return <Button
            style={{ width: "60%", height: "35px" }}
            icon={PrimeIcons.DOWNLOAD}
            className="p-button-praimary"
            loading={props.loading}
            onClick={(e) => {
                props.downloadDocumentFile(rowData.fileId, rowData.title + ("." + rowData.fileId?.split(".")?.[1] ?? ".pdf"));
                e.preventDefault();
            }}
        >
        </Button>
    }

    const tawreed: TawreedTableProps = {
        ds: {
            mode: 'basic',
            data: props.data ? props.data : [],
        },
        name: 'lbl_document_files',
        dataKey: '',
        columns: [

            {
                field: "title",
                header: "lbl_document_files_title",
                style: CssUtils.width('20%'),
                body: (options: any) => {
                    return (
                        <p>{options.title}</p>
                    )
                },
            },
            {
                field: "partnerName",
                header: "lbl_users_partner_name",
                style: CssUtils.width('20%'),
                body: (options: any) => {
                    return (
                        <p>{options.partnerName ?? ""}</p>
                    )
                },
            },
            {
                field: "createdAt",
                header: "lbl_reports_statements_createdat",
                style: CssUtils.width('20%'),
                body: (options: any) => {
                    return (
                        <p>{DateUtils.format(options.createdAt, 'date')}</p>
                    )
                },
            },

            {
                dataType: 'numeric',
                field: "download",
                style: CssUtils.width('5%'),
                body: downloadButton
            },

        ],
        header: {
            left: {
                search: false,
                custom: (
                    <span>
                        {tawreedTranslationService.translate(props.title)}
                    </span>
                )
            },
        }
    };

    return (
        <React.Fragment>
            <div className="col-12 md:col-12">
                <TawreedTable {...tawreed} />
            </div>
        </React.Fragment>
    );
}
