import { JObject } from "../../../../common/data/models"
import { Result } from "../../../../common/pagination"
import { tawreedTranslationService } from "../../../../common/translations"
import { ObjectUtils } from "../../../../helpers/object"
import { ReportResultDto, ReportTableTotalItemDto, StatementReportItem } from "../../domain"
import { StatementReportItemMapper } from "./statements-item"
import { UrlsMapper } from "./urls"

export const StatementReportMapper = {

    fromJson(obj: JObject, scale: number, sortDirection: number): ReportResultDto<StatementReportItem> {

        const statements: Result<StatementReportItem> = obj.transactions as Result<StatementReportItem>;
        if (!statements.content) {
            statements.content = [];
        }
        statements.content = statements.content.map((e: JObject) => StatementReportItemMapper.fromJson(e));
        statements.totalElements++;
        if (statements.last) {
            if (statements.numberOfElements === statements.size) {
                statements.numberOfElements++;
                statements.size++;
            }
            statements.content.push(StatementReportItemMapper.fromJson({
                langCode: 'all',
                description: {"all": obj.openingBalanceDescription},
                balance: obj.openingBalance,
            }));
        }

        const columns = ['lbl_reports_financial_statements_value'];
        const rows: string[] = ['lbl_reports_financial_statements_partner', 'lbl_reports_financial_statements_account', 'lbl_reports_statements_balance', 'lbl_reports_statements_creditamount', 'lbl_reports_statements_debitamount'];
        const value: ReportTableTotalItemDto[] = [];

        value.push({name: 'lbl_reports_financial_statements_partner', label: tawreedTranslationService.translate('lbl_reports_financial_statements_partner'), values: [obj.partnerName]});
        value.push({name: 'lbl_reports_financial_statements_account', label: tawreedTranslationService.translate('lbl_reports_financial_statements_account'), values: [obj.accountTitle]});
        value.push({name: 'lbl_reports_statements_balance', label: tawreedTranslationService.translate('lbl_reports_statements_balance'), values: [ObjectUtils.toFixed(obj.balance, scale)]});
        value.push({name: 'lbl_reports_statements_creditamount', label: tawreedTranslationService.translate('lbl_reports_statements_creditamount'), values: [ObjectUtils.toFixed(obj.totalCredit, scale)]});
        value.push({name: 'lbl_reports_statements_debitamount', label: tawreedTranslationService.translate('lbl_reports_statements_debitamount'), values: [ObjectUtils.toFixed(obj.totalDebit, scale)]});
        
        return {
            urls: obj.reportUrls ? UrlsMapper.fromJson(obj.reportUrls) : undefined,
            data: {
                error: undefined,
                message: undefined,
                status: 200,
                data: statements,
            },
            totals: {
                columns,
                rows,
                value,
            },
        }
    }
}