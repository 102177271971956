import { TawreedRoleName, UserPermissions } from "../../domain";
import { Routes } from "../../../../router";
import { TawreedActionName } from "../../../../common/actions";

export class PermissionsRepository {

    private readonly administrator: UserPermissions = {
        actions: [],
        routes: []
    }
    private readonly sysAdmin: UserPermissions = {
        actions: [],
        routes: []
    }

    private readonly manager: UserPermissions = {
        actions: [],
        routes: []
    }

    private readonly sales: UserPermissions = {
        actions: [],
        routes: []
    }

    private readonly delivery: UserPermissions = {
        actions: [],
        routes: []
    }

    private readonly store: UserPermissions = {
        actions: [
            TawreedActionName.ReportsExport,
            TawreedActionName.ReportsGenerate,
            TawreedActionName.UsersSubmit,
            TawreedActionName.FinanceDocumentsCreate,

            TawreedActionName.DashboardCardsSales,
            TawreedActionName.DashboardCardsOrdersPlaced,
            TawreedActionName.DashboardCardsOrdersProcessing,
            TawreedActionName.DashboardCardsOrdersShipped,
            TawreedActionName.DashboardCardsOrdersReturnShipped,
            TawreedActionName.DashboardChartOrdersTotals,
            TawreedActionName.DashboardCardBalance,
            TawreedActionName.DashboardCardsOrdersReadyForDelivery,
            TawreedActionName.DashboardChartOrdersPerZone,
            TawreedActionName.DashboardChartOrders,

            TawreedActionName.PartnersDetails,

            TawreedActionName.PartnerInformation,
            TawreedActionName.ProductsDetails,
            TawreedActionName.PartnersFilesDownload,

            TawreedActionName.StoreProductsCreate,
            TawreedActionName.StoreProductsDetails,
            TawreedActionName.StoreProductsDelete,
            TawreedActionName.StoreProductsSubmit,
            TawreedActionName.StoreProductsStageImport,
            TawreedActionName.StoreProductsStageApprove,
            TawreedActionName.StoreProductsStageReject,
            TawreedActionName.StoreProductsDownloadTemplate,
            TawreedActionName.StoreProductsActivate,
            TawreedActionName.StoreProductsDeactivate,

            TawreedActionName.CartsCreate,
            TawreedActionName.CartsDetails,
            TawreedActionName.CartsUpdate,
            TawreedActionName.CartsStoreCartDelete,
            TawreedActionName.CartsStoreCartItemDelete,
            TawreedActionName.OrdersPartnersDetails,
            TawreedActionName.OrdersDetails,
            TawreedActionName.OrdersConfirm,
            TawreedActionName.OrdersShip,
            TawreedActionName.OrdersDeliver,
            TawreedActionName.OrdersCancel,
            TawreedActionName.OrdersReturn,
            TawreedActionName.OrdersPrintStore,
            TawreedActionName.OrdersPrintCustomer,
            TawreedActionName.OrdersEditQuantity,
            TawreedActionName.OrderEditDiscountValue,
            TawreedActionName.OrdersEditBonus,
            TawreedActionName.OrderApplyDiscount,
            TawreedActionName.OrdersEditRetailPrice,
            TawreedActionName.OrdersEditDiscountPercent,
            TawreedActionName.OrdersPartnersDetails,
            TawreedActionName.OrdersPartnersShipAll,
            TawreedActionName.OrdersPartnersReturnAll,
            TawreedActionName.OrdersPartnersPrintAll,
            TawreedActionName.OrdersPartnersPrint,
            TawreedActionName.OrdersReadyForDelivery,
            TawreedActionName.OrdersReturnCreate,

            TawreedActionName.PartnersFilesDownload,

            TawreedActionName.UsersChangePassword,
            TawreedActionName.UsersCreate,
            TawreedActionName.UsersDetails,
            //TawreedActionName.UsersDelete,
            TawreedActionName.UsersSubmit,
            TawreedActionName.ImpoerHelperDetails,
            TawreedActionName.ImportUploadTask,
            TawreedActionName.StorePartnerSubmit,

            //posts
            TawreedActionName.PostCreate,
            TawreedActionName.PostDetails,
            TawreedActionName.PostActivate,
            TawreedActionName.PostDeactivate,
            TawreedActionName.PostDelete,
            TawreedActionName.PostSubmit,




        ],
        routes: [
            Routes.Login,
            Routes.Notifications,
            Routes.TawreedError,
            Routes.Dashboard,
            Routes.PartnersCustomersDetails,
            Routes.PartnersStoresDetails,

            Routes.ImportStoreProductsSearch,
            Routes.StoreProductsSearch,
            Routes.SellerStoreProductSearch,
            Routes.StoreProductsNew,
            Routes.StoreProductsDetails,
            Routes.ProductsDetails,

            Routes.UnverifiedProductsDetails,

            Routes.SalesCartsSearch,
            Routes.SalesCartsNew,
            Routes.SalesCartNew,
            Routes.SalesCartsDetails,

            Routes.SalesOrdersSearch,
            Routes.SalesOrdersDetails,
            Routes.SalesOrdersPlacedSearch,
            Routes.SalesOrdersPlacedDetails,
            Routes.SalesOrdersProcessingSearch,
            Routes.SalesOrdersProcessingDetails,
            Routes.SalesOrdersShippedSearch,
            Routes.SalesOrdersReadySearch,
            Routes.SalesOrdersReadyDetails,
            Routes.SalesOrdersShippedDetails,
            Routes.SalesOrderTrackingHostory,

            Routes.ReportsFinancialStatementsPerPartner,
            Routes.TransactionsDetails,
            Routes.ReportsSalesOrders,
            Routes.ReportsInvoices,
            Routes.UsersDetails,
            Routes.ImportHelper,
            Routes.ImportHelperDetails,
            Routes.UsersSearch,
            Routes.UsersNew,
            Routes.PartnerConnectionsSearch,
            // Routes.SalesOfferSearch,
            // Routes.SalesOfferNew,
            // Routes.SalesOfferDetails,

             //posts
             Routes.PostsSearch,
             Routes.PostsDetails,
             Routes.PostsNew,
             Routes.PostView,
 
        ],
    }

    private readonly salesMan: UserPermissions = {
        actions: [
            TawreedActionName.ReportsExport,
            TawreedActionName.ReportsGenerate,
            TawreedActionName.UsersSubmit,
            TawreedActionName.FinanceDocumentsCreate,

            //TawreedActionName.DashboardCardsSales,
            TawreedActionName.DashboardCardsOrdersPlaced,
            TawreedActionName.DashboardCardsOrdersProcessing,
            // TawreedActionName.DashboardCardsOrdersShipped,
            // TawreedActionName.DashboardCardsOrdersReturnShipped,
            // TawreedActionName.DashboardChartOrdersTotals,
            // TawreedActionName.DashboardCardBalance,
            // TawreedActionName.DashboardCardsOrdersReadyForDelivery,
            // TawreedActionName.DashboardChartOrdersPerZone,
            // TawreedActionName.DashboardChartOrders,

            // TawreedActionName.PartnersDetails,

            // TawreedActionName.PartnerInformation,
            TawreedActionName.ProductsDetails,


            TawreedActionName.StoreProductsDetails,
            TawreedActionName.StoreProductsStageImport,
            TawreedActionName.StoreProductsStageApprove,
            TawreedActionName.StoreProductsStageReject,

            TawreedActionName.CartsCreate,
            TawreedActionName.CartsDetails,
            TawreedActionName.CartsUpdate,
            TawreedActionName.CartsStoreCartDelete,
            TawreedActionName.CartsStoreCartItemDelete,

            // TawreedActionName.OrdersPartnersDetails,
            // TawreedActionName.OrdersDetails,
            // TawreedActionName.OrdersConfirm,
            // TawreedActionName.OrdersShip,
            // TawreedActionName.OrdersDeliver,
            // TawreedActionName.OrdersCancel,
            // TawreedActionName.OrdersReturn,
            // TawreedActionName.OrdersPrintStore,
            // TawreedActionName.OrdersPrintCustomer,
            // TawreedActionName.OrdersEditQuantity,
            // TawreedActionName.OrderEditDiscountValue,
            // TawreedActionName.OrdersEditBonus,
            // TawreedActionName.OrdersEditRetailPrice,
            // TawreedActionName.OrdersEditDiscountPercent,
            // TawreedActionName.OrdersPartnersDetails,
            // TawreedActionName.OrdersPartnersShipAll,
            // TawreedActionName.OrdersPartnersReturnAll,
            // TawreedActionName.OrdersPartnersPrintAll,
            // TawreedActionName.OrdersPartnersPrint,
            // TawreedActionName.OrdersReadyForDelivery,
            // TawreedActionName.OrdersReturnCreate,

            TawreedActionName.PartnersFilesDownload,

            TawreedActionName.UsersChangePassword,
            TawreedActionName.UsersCreate,
            TawreedActionName.UsersDetails,
            //TawreedActionName.UsersDelete,
            TawreedActionName.UsersSubmit,
            TawreedActionName.StorePartnerSubmit



        ],
        routes: [
            Routes.Login,
            Routes.Notifications,
            Routes.TawreedError,
            Routes.Dashboard,
            Routes.PartnersCustomersDetails,
            Routes.PartnersStoresDetails,

            Routes.StoreProductsSearch,
            Routes.SellerStoreProductSearch,
            Routes.StoreProductsDetails,
            Routes.ProductsDetails,

            Routes.UnverifiedProductsDetails,

            Routes.SalesCartsSearch,
            Routes.SalesCartsNew,
            Routes.SalesCartNew,
            Routes.SalesCartsDetails,

            // Routes.SalesOrdersSearch,
            // Routes.SalesOrdersDetails,
            // Routes.SalesOrdersPlacedSearch,
            // Routes.SalesOrdersPlacedDetails,
            // Routes.SalesOrdersProcessingSearch,
            // Routes.SalesOrdersProcessingDetails,
            // Routes.SalesOrdersShippedSearch,
            // Routes.SalesOrdersReadySearch,
            // Routes.SalesOrdersReadyDetails,
            // Routes.SalesOrdersShippedDetails,
            Routes.PartnerConnectionsSearch,

            //Routes.ReportsFinancialStatementsPerPartner,
            Routes.ReportsSalesOrders,
            //Routes.ReportsInvoices,
            Routes.UsersDetails,
            //Routes.UsersSearch,
            //Routes.UsersNew,
            //Routes.PartnerConnectionsSearch
            // Routes.SalesOfferSearch,
            // Routes.SalesOfferNew,
            // Routes.SalesOfferDetails,


        ],
    }
    private readonly storeReviewer: UserPermissions = {
        actions: [
            TawreedActionName.ReportsExport,
            TawreedActionName.ReportsGenerate,
            TawreedActionName.UsersSubmit,
            TawreedActionName.FinanceDocumentsCreate,

            TawreedActionName.DashboardCardsOrdersPlaced,
            TawreedActionName.ProductsDetails,


            TawreedActionName.StoreProductsDetails,


            TawreedActionName.OrdersPartnersDetails,
            TawreedActionName.OrdersDetails,
            TawreedActionName.OrdersPrintStore,
            TawreedActionName.OrdersPrintCustomer,
            TawreedActionName.OrdersPartnersPrint,
            TawreedActionName.OrdersConfirm,
            TawreedActionName.OrdersCancel,
            TawreedActionName.OrdersEditQuantity,
            TawreedActionName.OrderEditDiscountValue,
            TawreedActionName.OrdersEditBonus,
            TawreedActionName.OrdersEditRetailPrice,
            TawreedActionName.OrdersEditDiscountPercent,

            TawreedActionName.PartnersFilesDownload,

            TawreedActionName.UsersChangePassword,
            TawreedActionName.UsersDetails,
            //TawreedActionName.UsersDelete,
            TawreedActionName.UsersSubmit,
            TawreedActionName.StorePartnerSubmit



        ],
        routes: [
            Routes.Login,
            Routes.Notifications,
            Routes.TawreedError,
            Routes.Dashboard,
            Routes.PartnersCustomersDetails,

            Routes.StoreProductsSearch,
            Routes.SellerStoreProductSearch,
            Routes.StoreProductsDetails,
            Routes.ProductsDetails,

            Routes.UnverifiedProductsDetails,


            //Routes.SalesOrdersSearch,
            Routes.SalesOrdersDetails,
            Routes.SalesOrdersPlacedSearch,
            Routes.SalesOrdersPlacedDetails,
            //Routes.SalesOrdersProcessingSearch,
            //Routes.SalesOrdersProcessingDetails,
            //Routes.SalesOrdersShippedSearch,
            //Routes.SalesOrdersReadySearch,
            //Routes.SalesOrdersReadyDetails,
            //Routes.SalesOrdersShippedDetails,

            Routes.UsersDetails,
            Routes.ReportsSalesOrders,


        ],
    }
    
    private readonly storeWH: UserPermissions = {
        actions: [
            TawreedActionName.ReportsExport,
            TawreedActionName.ReportsGenerate,
            TawreedActionName.UsersSubmit,
            TawreedActionName.FinanceDocumentsCreate,

            TawreedActionName.DashboardCardsOrdersReadyForDelivery,
            TawreedActionName.DashboardCardsOrdersProcessing,
            TawreedActionName.ProductsDetails,


            TawreedActionName.StoreProductsDetails,

            TawreedActionName.OrdersPartnersDetails,
            TawreedActionName.OrdersDetails,
            TawreedActionName.OrdersPrintStore,
            TawreedActionName.OrdersPrintCustomer,
            TawreedActionName.OrdersPartnersDetails,
            TawreedActionName.OrdersPartnersPrint,
            TawreedActionName.OrdersShip,
            TawreedActionName.OrdersReadyForDelivery,

            TawreedActionName.PartnersFilesDownload,

            TawreedActionName.UsersChangePassword,
            TawreedActionName.UsersDetails,
            TawreedActionName.UsersSubmit,
            TawreedActionName.StorePartnerSubmit



        ],
        routes: [
            Routes.Login,
            Routes.Notifications,
            Routes.TawreedError,
            Routes.Dashboard,
            Routes.PartnersCustomersDetails,

            Routes.StoreProductsSearch,
            Routes.SellerStoreProductSearch,
            Routes.StoreProductsDetails,
            Routes.ProductsDetails,

            Routes.UnverifiedProductsDetails,


            Routes.SalesOrdersProcessingSearch,
            Routes.SalesOrdersProcessingDetails,
            Routes.SalesOrdersReadySearch,
            Routes.SalesOrdersReadyDetails,
            Routes.SalesOrdersDetails,

            Routes.UsersDetails,


        ],
    }

    private readonly customer: UserPermissions = {
        actions: [],
        routes: []
    }

    private readonly topup: UserPermissions = {
        actions: [],
        routes: []
    }

    private readonly finance: UserPermissions = {
        actions: [],
        routes: []
    }
    private readonly walletAdmin: UserPermissions = {
        actions: [],
        routes: []
    };
    private readonly catManager: UserPermissions = {
        actions: [],
        routes: []
    };
    private readonly storeDelivery: UserPermissions = {
        actions: [
            TawreedActionName.DashboardCardsOrdersShipped,
            TawreedActionName.DashboardCardsOrdersReadyForDelivery,
            TawreedActionName.ReportsExport,

            TawreedActionName.PartnersFilesDownload,
            TawreedActionName.UsersSubmit,

            TawreedActionName.OrdersDetails,
            TawreedActionName.OrdersShip,
            TawreedActionName.OrdersPartnersShipAll,
            TawreedActionName.OrdersPrintStore,
            TawreedActionName.OrdersPrintCustomer,
            TawreedActionName.OrdersPartnersDetails,
            TawreedActionName.OrdersPartnersPrintAll,
            TawreedActionName.OrdersPartnersPrint,
            TawreedActionName.OrdersDeliver,
            TawreedActionName.OrdersCancel,
            TawreedActionName.UsersChangePassword,
            TawreedActionName.ReportsGenerate,
            TawreedActionName.ReportsExport

        ],
        routes: [
            Routes.Login,
            Routes.Notifications,
            Routes.TawreedError,
            Routes.Dashboard,


            Routes.SalesOrdersSearch,
            Routes.SalesOrdersDetails,


            Routes.SalesOrdersShippedSearch,
            Routes.SalesOrdersShippedDetails,

            Routes.SalesOrdersReadySearch,
            Routes.SalesOrdersReadyDetails,


            Routes.UsersDetails,

            Routes.ReportsSalesOrders,


        ],
    }




    public getPermissions(role: TawreedRoleName): Promise<UserPermissions> {
        return new Promise<UserPermissions>((resolve, reject) => {

            switch (role) {
                case "ADMIN":
                    return resolve(this.administrator);
                case "SYS_ADMIN":
                    return resolve(this.sysAdmin);
                case "WALLET_ADMIN":
                    return resolve(this.walletAdmin);
                case "CATMANAGER":
                    return resolve(this.catManager);
                case "STORE_DELIVERY":
                    return resolve(this.storeDelivery);
                case "MANAGER":
                    return resolve(this.manager);
                case "SALES":
                    return resolve(this.sales);
                case "DELIVERY":
                    return resolve(this.delivery);
                case "STORE_ADMIN":
                    return resolve(this.store);
                case "CUSTOMER_ADMIN":
                    return resolve(this.customer);
                case "TOPUP_ADMIN":
                    return resolve(this.topup);
                case "FINANCE":
                    return resolve(this.finance);
                case "STORE_SALES_MAN":
                    return resolve(this.salesMan);
                case "STORE_WH":
                    return resolve(this.storeWH);
                case "STORE_REVIEWER":
                    return resolve(this.storeReviewer);
                default:
                    return reject('MSG_ROLENAME_UNKNOWN');
            }
        });
    }
}
